.app {
  display: flex;
  flex: 1;
  min-width: 100vw;
  min-height: 100vh;
}

.main-app {
  display: flex;
  flex: 1;
  min-width: 100vw;
  min-height: 100vh;
}

.breakpoint-view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

svg {
  object-fit: scale-down;
  height: auto;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

input:focus {
  outline: none !important;
}

.medium {
  font-size: medium;
}

.block {
  display: block;
}

.inline {
  display: inline;
}

.bold {
  font-family: CircularStdBold;
}

.flex {
  display: flex;
}

.row-wrap {
  flex-flow: row wrap;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-stretch {
  align-items: stretch;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.bordered {
  border: 1px solid #f5f3f3;
}

.bordered-0 {
  border: 0;
}

.bordered-black {
  border: 1px solid #000000 !important;
}

.border-color-purple {
  border-color: #72004c;
}

.border-color-gray {
  border-color: #f8fafc;
}

.dashed {
  border-width: 1px;
  border-style: dashed;
  border-color: #d9dae4;
}

.height-50 {
  height: 50vh;
}

.height-40 {
  min-height: 40vh;
  height: 40vh;
}

.height-30 {
  height: 30vh;
}
.height-20 {
  height: 20vh;
}

.max-height-80 {
  max-height: 80vh;
}
.max-height-85 {
  max-height: 85vh;
}
.max-height-90 {
  max-height: 90vh;
}
.max-height-100 {
  max-height: 100vh;
}

.scroll {
  overflow: auto;
}

.hide-overflow {
  overflow: hidden;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-basis-90 {
  flex-basis: 90%;
}
.flex-basis-80 {
  flex-basis: 80%;
}
.flex-basis-70 {
  flex-basis: 70%;
}
.flex-basis-60 {
  flex-basis: 60%;
}

.flex-basis-55 {
  flex-basis: 55%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-45 {
  flex-basis: 45%;
}
.flex-basis-40 {
  flex-basis: 40%;
}

.flex-basis-35 {
  flex-basis: 35%;
}

.flex-basis-30 {
  flex-basis: 30%;
}
.flex-basis-20 {
  flex-basis: 20%;
}

.grow {
  flex-grow: 1;
}
.shrink {
  flex-shrink: 1;
}

.p-0 {
  padding: 0 !important;
}
.p-p5 {
  padding: 0.5em;
}

.p-1 {
  padding: 1em;
}

.p-h-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.p-h-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.p-v-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}

.p-2 {
  padding: 2em;
}

.p-h-2 {
  padding-left: 2em;
  padding-right: 2em;
}

.p-h-3 {
  padding-left: 3em;
  padding-right: 3em;
}

.p-h-4 {
  padding-left: 4em;
  padding-right: 4em;
}

.p-h-p5 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.p-v-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}

.p-v-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}

.p-t-1 {
  padding-top: 1em;
}
.p-b-1 {
  padding-bottom: 1em;
}
.p-t-p5 {
  padding-top: 0.5em;
}
.p-b-p5 {
  padding-bottom: 0.5em;
}
.p-l-1 {
  padding-left: 1em;
}
.p-l-p5 {
  padding-left: 0.5em;
}

.p-r-1 {
  padding-right: 1em;
}

.p-t-2 {
  padding-top: 2em;
}
.p-b-2 {
  padding-bottom: 2em;
}
.p-l-2 {
  padding-left: 2em;
}
.p-r-2 {
  padding-right: 2em;
}

.r-8 {
  border-radius: 8px;
}

.circle {
  border-radius: 50%;
}

.bg-w {
  background-color: white;
}

.bg-peach {
  background: #fbf9f9;
}

.bg-cream {
  background: #f2efeb;
}

.gap-half {
  gap: 0.5em;
}

.gap-1 {
  gap: 1em;
}

.gap-1p5 {
  gap: 1.5em;
}

.gap-2 {
  gap: 2em;
}

.gap-4 {
  gap: 4em;
}

.pointer {
  cursor: pointer;
}

.m-0 {
  margin: 0;
}

.m-1 {
  margin: 1em;
}

.m-h-p5 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.m-h-1 {
  margin-left: 1em;
  margin-right: 1em;
}

.m-h-0 {
  margin-left: 0;
  margin-right: 0;
}

.m-v-0 {
  margin-top: 0em;
  margin-bottom: 0em;
}

.m-v-half {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.m-v-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.m-v-p5 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.m-2 {
  margin: 2em;
}

.m-h-2 {
  margin-left: 2em;
  margin-right: 2em;
}

.m-v-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}

.m-t-1 {
  margin-top: 1em;
}

.m-b-1 {
  margin-bottom: 1em;
}
.m-l-1 {
  margin-left: 1em;
}
.m-r-1 {
  margin-right: 1em;
}

.m-t-p5 {
  margin-top: 0.5em;
}
.m-b-p5 {
  margin-bottom: 0.5em;
}
.m-r-p5 {
  margin-right: 0.5em;
}
.m-l-p5 {
  margin-left: 0.5em;
}
.m-t-2 {
  margin-top: 2em;
}
.m-b-2 {
  margin-bottom: 2em;
}
.m-l-2 {
  margin-left: 2em;
}
.m-r-2 {
  margin-right: 2em;
}

.m-t-auto {
  margin-top: auto;
}
.m-b-auto {
  margin-bottom: auto;
}
.m-v-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.shadow {
  box-shadow: 0px 4px 16px rgba(21, 21, 21, 0.08);
}

.form-row {
  display: flex;
  align-items: center;
}

.form-row + .form-row {
  padding-top: 2em;
}

.input-field {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.input-field + .input-field {
  margin-left: 1em;
}

.input-field > label {
  font-family: CircularStd;
  font-style: normal;
  font-weight: normal;
  font-size: small;
  line-height: 18px;
  color: #000000;
  padding-bottom: 6px;
}

.input-field > input,
select,
.input,
.pseudo-input {
  width: 100%;
  min-height: 50px;
  border: 1px solid #d9dae4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: medium;
  font-family: CircularStd;
  line-height: 20px;
}

.input-field > textarea {
  width: 100%;
  border: 1px solid #d9dae4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 15px 20px;
  font-family: CircularStd;
  font-size: medium;
  line-height: 20px;
}

.input-field > input:focus,
select:focus,
.pseudo-input:focus {
  border: 1px solid #72004c;
  box-sizing: border-box;
  box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
  border-radius: 6px;
}

.jc-flex-end {
  justify-content: flex-end;
}

.input-field > .pseudo-input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: medium;
}

.input-field > input:focus {
  box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
  outline: 1px solid #72004c;
}

.pseudo-phone-input {
  width: 100%;
  min-height: 50px;
  border: 1px solid #d9dae4;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  display: flex;
}

.phone-input-selector-section {
  padding-right: 1em;
  display: flex;
  position: relative;
  cursor: pointer;
}

.phone-input-selector-section > p {
  white-space: nowrap;
}

.phone-input-selector-section > .selected {
  background: #f4f6fa;
}

.phone-input-selector-section > .country-code-dropdown:hover {
  background: #f4f6fa;
}

.pseudo-phone-input > phone-input-number-section {
  display: flex;
  align-items: center;
  width: 80%;
  height: 100%;
}

.phone-input-number-section > input {
  width: 80%;
  height: 60%;
  padding: 0 15px;
  font-family: CircularStd;
  font-size: medium;
  line-height: 20px;
  border: 0;
  border-left: 0.01em solid rgb(114, 108, 108);
}

.phone-input-number-section > input:focus {
  outline: none;
}

.pseudo-phone-input:focus {
  box-shadow: 0px 0px 0px 4px rgba(114, 0, 76, 0.08);
  outline: 1px solid #72004c;
}

.dropdown {
  position: absolute;
  top: 105%;
  width: 100%;
  border: 1px solid #d9dae4;
  border-radius: 8px;
  background: white;
  padding: 0.5em 0;
  z-index: 10;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.dropdown-item {
  padding: 0.5em 20px;
  cursor: pointer;
  color: gray;
}

.dropdown-item:hover {
  background: #f4f6fa;
  /* background: red !important; */
}

.error-msg {
  color: red;
  margin: 0;
  margin-top: 0.5em;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.purple {
  color: #72004c;
}
.white {
  color: white;
}
.red {
  color: red;
}

.green {
  color: #2ba20d;
}

.gray {
  color: #696969;
}

.gray-2 {
  color: #9b9b9b;
}

.hide {
  display: none;
}

.display-inline {
  display: inline;
}

.drop-down {
  position: absolute;
  top: 110%;
  bottom: auto;
}

.drop-up {
  position: absolute;
  top: auto;
  bottom: 110%;
}

.border-bottom-purple {
  border-bottom: 2px solid #72004c;
}

.border-bottom-gray-2 {
  border-bottom: 1px solid #f8fafc;
}

.border-bottom {
  border: 1px solid;
}

.bg-gray {
  background-color: #f8fafc;
}

.bg-gray-on-hover:hover {
  background-color: #f8fafc;
}

.bg-purple {
  background: #72004c;
}

.bg-black {
  background-color: #151515;
}

.bg-transparent {
  background-color: transparent;
}

.primary {
  background-color: #72004c;
  color: white;
}

.secondary {
  background-color: white;
  color: #72004c;
}

.full-width-percent {
  width: 100%;
}

.width-100 {
  width: 100%;
}

.small-text {
  font-size: 0.7em;
}

.meta {
  /* color: ${() => Colors.grey_2}; */
  color: #696969;
  margin: 0.2em 0;
  font-size: small;
}

.border-box {
  box-sizing: border-box;
}

.half-transparent {
  opacity: 0.5;
}

.text-decoration-none {
  text-decoration: none;
}

.list-style-type-none {
  list-style-type: none;
}

.light-grey {
  color: rgba(105, 105, 105, 0.4);
}

.max-width-100 {
  width: 100%;
  max-width: 100%;
}

.p-v-p5 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.no-data {
  -moz-box-shadow: 0px 1px 5px 0px #9b9b9b !important;
  -webkit-box-shadow: 0px 1px 5px 0px #9b9b9b !important;
  box-shadow: 0px 1px 5px 0px #9b9b9b !important;
  border: 0px !important;
}

.background-size-cover {
  background-size: cover;
}

.h-w-5 {
  height: 5em;
  width: 5em;
}
.h-w-6 {
  height: 6em;
  width: 6em;
}
/* .flex-1 */
.show-on-breakpoint {
  @media (max-width: 1024px) {
    display: initial;
  }
  @media (min-width: 1024px) {
    display: none;
  }
}

.hide-on-breakpoint {
  @media (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1024px) {
    display: initial;
  }
}

.checkbox-wrapper {
  /* border: 1px solid; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-wrapper > input[type="checkbox"] {
  appearance: none;
  background-color: #f1eeee;
  width: 15px;
  height: 15px;
  border-radius: 1.5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.checkbox-wrapper > input[type="checkbox"]::after {
  content: "\2713";
  font-weight: 900;
  color: white;
  display: none;
}

.checkbox-wrapper > input[type="checkbox"]:hover {
  background-color: #d5d5d5;
}

.checkbox-wrapper > input[type="checkbox"]:checked {
  background-color: #72004c;
}

.checkbox-wrapper > input[type="checkbox"]:checked:after {
  display: block;
}

.danger {
  color: indianred;
}

.prevent-line-break {
  white-space: nowrap;
}

.pay-btn-container {
  display: flex;
  justify-content: flex-end;
}

.paystack-button {
  background: #72004c;
  border-radius: 8px;
  color: white;
  border: 0;
  padding: 1em 2em;
  cursor: pointer;
}

.upload-image-circle {
  height: 5em;
  width: 5em;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.not-found-page {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex: 1;
}

.not-found-text {
  text-align: center;
  font-size: 10em;
  margin: 0;
}
.not-found-subtext {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: #9b9b9b;
  margin: 0;
}

.PhoneInput {
  background-color: #fff;
  font-family: regular;
  width: 100%;
  border: 1px solid #d9dae4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 0 1rem;
  font-size: medium;
}
.PhoneInputInput {
  /* border: 1px solid red !important; */
  border: none !important;
  background-color: #fff !important;
  margin: 0 !important;
  padding: 1rem;
  font-size: medium;
}

.PhoneInputCountryFlag {
  border: none !important;
}
